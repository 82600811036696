import Image from 'next/image'
import Link from 'next/link'

import { useShowroomQuery } from '@/features/klass/queries/useShowroomQuery'

export default function Home() {
  const { data: showroomList } = useShowroomQuery('home', 'kdt')

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
      <h1>내 강의실 페이지(고도화)</h1>
      <Link href={'/oauth'}>로그인 페이지로 이동</Link>
      <Link href={'/oauth/setup-password'}>초기 비밀번호 설정 페이지로 이동</Link>
      <Link href={'/undefined'}>강의보드 페이지로 이동</Link>
    </div>
  )
}
