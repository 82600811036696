import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import QueryString from 'qs'

import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

export const fetchShowRoomList = async (location: string, section: string, key?: string) => {
  const requestQueryString = QueryString.stringify({
    ...(key
      ? { filters: `location:${location}|section:${section}|filter_key:${key}` }
      : { filters: `location:${location}|section:${section}` }),
  })

  const resp = await axios.get<any[]>(
    `${window.apiEndpoint}/course/v1/showrooms?${requestQueryString}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const useShowroomQuery = (location: string, section: string, key?: string) => {
  return useQuery({
    queryKey: ['showroom', section],
    queryFn: () => fetchShowRoomList(location, section, key),
    staleTime: 60 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
  })
}
